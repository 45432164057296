.navListItem>.pageLink{
    font-size: 1.1vw;
    color: var(--text-body);
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
  }
  .navListItem>.pageLink svg{
    width: 0.7vw;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 2px;
  }
  .navListItem>.pageLink.dropdown::before{
    content: '';
    height: 70px;
    background: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .navListItem>.pageLink span{
    position: relative;
  }
  .navListItem>.pageLink span::after{
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    background: var(--primary);
    width: 100%;
    height: 1.5px;
    transform: scaleX(0);
    transform-origin: right;
    transition: var(--transition-2);
  }
  .navListItem:hover>.pageLink span::after{
    transform: scaleX(1);
    transform-origin: left;
  }
  .navListItem:hover>.pageLink{
    color: var(--primary);
  }
  
  /* Nav Dropdown */
  .navDropdown{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding-top: 5vw;
    background: #ffffff;
    border-top: 2px solid var(--gray-100);
    z-index: -1;
    box-shadow: 1px 1px 10px #00000010;
    border-end-start-radius: 20px;
    border-end-end-radius: 20px;
  }
  
  .navDropdownList{
    position: relative;
    width: 85%;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 2vw 0;
    justify-items: center;
    row-gap: 2vw;
  }
  
  .navDropListHover{
    transition: all 0.3s ease;
    padding: 6px 20px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    gap: 1vw;
  }
  .navDropListHover:hover{
    background: var(--gray-100);
  }
  
  .navDropListHover .img{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    background-color: #2A2A2A;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
  }
  .navDropListHover .img img{
    width: 100%;
  }
  .navDropListHover:hover .img{
    scale: 0.95;
    background-color: var(--primary);
  }
  
  .navDropListHover:hover .navDropTitle{
    color: var(--primary);
  }
  .navDropTitle{
    font-size: 1.2vw;
    color: var(--text-head);
    font-weight: 500;
    transition: all 0.3s ease;
  }
  .navDropText{
    font-size: 0.9vw;
  }