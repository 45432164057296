/* Main button */
.btn {
  display: inline-flex;
  align-items: center;
  padding: 0 1.8vw;
  height: 4.2vw;
  position: relative;
  width: 14vw;
  max-width: 14vw;
  border-radius: 1000px;
  background: var(--text-head);
  color: white;
  font-size: 1.05vw;
  font-weight: 500;
  text-rendering: geometricPrecision;
}
.btn .btnCircle {
  clip-path: inset(0.8vw 0.9vw 0.8vw calc(100% - 3.7vw) round 4vw);
  position: absolute;
  inset: -1px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  padding: 0 1.85vw;
  z-index: 2;
  background-color: white;
  color: var(--text-head);
  transition: all 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.btn .btnCircle[data-demo] {
  padding: 0 1.75vw;
}
.btn .btnCircleText {
  padding: 0 1px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn .btnIcon {
  width: 0.8vw;
  height: 0.8vw;
  overflow: hidden;
}
.btn .btnIcon .btnPath {
  transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: center center;
  fill: currentColor;
}
.btn .btnIcon .btnPath:first-child {
  transform: translateX(-120%) scale(0);
}
.btn:hover .btnIcon .btnPath:first-child {
  transform: translateX(0) scale(1);
}
.btn:hover .btnIcon .btnPath:last-child {
  transform: translateX(120%) scale(0);
}
.btn:hover .btnCircle {
  clip-path: inset(0 round 4vw);
  background-color: var(--primary);
  color: white;
}

/* Button Secondary */
.btn[data-secondary] {
  background: none;
  color: var(--primary);
  border: 1px solid var(--primary);
}
.btn[data-secondary] .btnCircle {
  clip-path: inset(0.7vw 0.9vw 0.7vw calc(100% - 3.7vw) round 4vw);
  background-color: var(--primary);
  color: white;
}
.btn[data-secondary]:hover .btnCircle {
  clip-path: inset(0 round 4vw);
}
.btn .btnIcon[data-secondary-icon] .btnPath:first-child {
  transform: translateY(-120%) scale(0.5);
}
.btn:hover .btnIcon[data-secondary-icon] .btnPath:first-child {
  transform: translateY(0) scale(1);
}
.btn:hover .btnIcon[data-secondary-icon] .btnPath:last-child {
  transform: translateY(120%) scale(0.5);
}

.btn[data-thankyou]{
  max-width: 17.5vw;
  width: 17.5vw;
}

@media screen and (max-width: 1024px) {
  .btn {
    height: 11vw;
    padding: 0 4vw;
    max-width: 35vw;
    font-size: 3vw;
    width: 35vw;
    font-weight: 400;
  }
  .btn[data-secondary] .btnCircle {
    padding: 0 4vw;
    clip-path: inset(14% 5% 15% calc(100% - 27%) round 10vw);
  }
  .btn[data-secondary]:hover .btnCircle {
    clip-path: inset(0 round 10vw);
  }
  .btn .btnCircle[data-demo] {
    padding: 0 4vw;
    clip-path: inset(16% 6% 18% calc(100% - 27%) round 4vw);
  }
  .btn .btnCircle {
    padding: 0 4vw;
    clip-path: inset(16% 6% 18% calc(100% - 27%) round 4vw);
  }
  .btn .btnIcon {
    width: 2vw;
    height: 2vw;
  }
  .btn .btnCircleText {
    padding: 0 2% 0 0;
  }
  .btn:hover .btnCircle {
    clip-path: inset(0 round 4vw);
  }

  .btn[data-secondary] .btnCircle{
    padding: 0 4vw;
    clip-path: inset(14% 5% 15% calc(100% - 27%) round 10vw);
  }
  .btn[data-secondary]:hover .btnCircle{
    clip-path: inset(0 round 10vw);
  }


  .btn[data-thankyou]{
    width: 47vw;
    max-width: 47vw;
  }
  .btn[data-thankyou] .btnCircle{
    clip-path: inset(1.6vw 2vw 1.6vw calc(100% - 10vw) round 4vw);
  }

}

@media screen and (max-width: 540px) {
  .btn {
    height: 15vw;
    padding: 0 4.5vw;
    width: 50vw;
    max-width: 50vw;
    font-size: 4.2vw;
    font-weight: 400;
  }
  .btn[data-secondary] .btnCircle {
    padding: 0 4.5vw;
    clip-path: inset(14% 5% 15% calc(100% - 27%) round 10vw);
  }
  .btn[data-secondary]:hover .btnCircle {
    clip-path: inset(0 round 10vw);
  }
  .btn .btnCircle[data-demo] {
    padding: 0 4.5vw;
    clip-path: inset(18% 6% 18% calc(100% - 26%) round 10vw);
  }
  .btn .btnCircle {
    padding: 0 4.5vw;
    clip-path: inset(18% 6% 18% calc(100% - 26%) round 10vw);
  }
  .btn .btnIcon {
    width: 3.5vw;
    height: 3.5vw;
  }
  .btn .btnCircleText {
    padding: 0 3.5% 0 0;
  }
  .btn:hover .btnCircle {
    clip-path: inset(0 round 10vw);
  }

  .btn[data-thankyou]{
    width: 65vw;
    max-width: 65vw;
  }
  .btn[data-thankyou] .btnCircle{
    clip-path: inset(2vw 2.5vw 2vw calc(100% - 14vw) round 1000px);
  }
}